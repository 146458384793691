import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/Modal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Results() {
  const [analysis, setAnalysis] = useState(null);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isPaid, setIsPaid] = useState(false);

  const handlePaymentSuccess = () => {
    setIsPaid(true);
    localStorage.setItem('isPaid', 'true');
  };

  useEffect(() => {
    const analysisResults = JSON.parse(localStorage.getItem('analysisResults'));
    if (analysisResults) {
      setAnalysis(analysisResults);
      localStorage.removeItem('analysisResults');
    }
  }, []);

  if (!analysis) {
    return (
      <div className="App">
        <Header />
        <main>
          <section id="analysis-result">
            <h1>No analysis results found.</h1>
          </section>
        </main>
        <Footer />
      </div>
    );
  }

  const {
    fun_report,
    detailed_insights,
    hidden_gems,
    investment_opportunities,
    global_ranking,
    unusual_transactions,
  } = analysis;

  return (
    <div className="App">
      <Header />
      <main>
        <section id="analysis-result">
          <div id="analysis-message">
            {global_ranking && (
              <div className="result-section global-ranking">
                <h2>Your Global Financial Ranking</h2>
                <div className={`global-ranking-content ${isPaid ? '' : 'blurred-content'}`}>
                  <div className="ranking-details">
                    <p>You are in the top <strong>{global_ranking.percentile}%</strong> of the world population!</p>
                  </div>
                </div>
              </div>
            )}

            {fun_report && (
              <div className="result-section fun-report">
                <h2>Report</h2>
                <div className={isPaid ? '' : 'blurred-content'}>
                  <p><strong>Summary:</strong> {fun_report.summary}</p>
                  <p><strong>Observation:</strong> {fun_report.funny_observation}</p>
                  <p><strong>Savings Tip:</strong> {fun_report.savings_tip}</p>
                </div>
              </div>
            )}

            <div className="result-section detailed-insights">
              <h2>Detailed Insights</h2>
              <div className={isPaid ? '' : 'blurred-content'}>
                <ul>
                  <li><strong>Total Income:</strong> ${detailed_insights.total_income.toFixed(2)}</li>
                  <li><strong>Total Expenses:</strong> ${detailed_insights.total_expenses.toFixed(2)}</li>
                  <li><strong>Net Income:</strong> ${detailed_insights.net_income.toFixed(2)}</li>
                  <li>
                    <strong>Largest Expense:</strong> {detailed_insights.largest_expense.description} (${detailed_insights.largest_expense.amount.toFixed(2)}) on {new Date(detailed_insights.largest_expense.date).toLocaleDateString()}
                  </li>
                </ul>

                <div className="sub-section income-sources">
                  <h3>Income Sources</h3>
                  <ul>
                    {detailed_insights.income_sources.map((source, index) => (
                      <li key={index}>
                        {source.source}: ${source.amount.toFixed(2)} ({source.frequency})
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sub-section spending-categories">
                  <h3>Spending Categories</h3>
                  <ul>
                    {detailed_insights.spending_categories.map((category, index) => (
                      <li key={index}>
                        {category.category}: ${category.amount.toFixed(2)} ({category.percentage_of_total.toFixed(2)}%)
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="sub-section cash-flow-analysis">
                  <h3>Cash Flow Analysis</h3>
                  <h4>Monthly Trends</h4>
                  <ul>
                    {detailed_insights.cash_flow_analysis.monthly_trends.map((trend, index) => (
                      <li key={index}>
                        {trend.month}: Income - ${trend.income.toFixed(2)}, Expenses - ${trend.expenses.toFixed(2)}, Net Flow - ${trend.net_flow.toFixed(2)}
                      </li>
                    ))}
                  </ul>
                  <h4>Forecast</h4>
                  <ul>
                    <li><strong>Projected Income:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_income.toFixed(2)}</li>
                    <li><strong>Projected Expenses:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_expenses.toFixed(2)}</li>
                    <li><strong>Projected Savings:</strong> ${detailed_insights.cash_flow_analysis.forecast.projected_savings.toFixed(2)}</li>
                  </ul>
                </div>
              </div>
            </div>

            {unusual_transactions && unusual_transactions.length > 0 && (
              <div className="result-section unusual-transactions">
                <h2>Unusual Transactions</h2>
                <div className={isPaid ? '' : 'blurred-content'}>
                  <ul>
                    {unusual_transactions.map((transaction, index) => (
                      <li key={index}>
                        <strong>{transaction.description}</strong>: ${transaction.amount.toFixed(2)} on {new Date(transaction.date).toLocaleDateString()} - <em>{transaction.reason}</em>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {investment_opportunities && investment_opportunities.length > 0 && (
              <div className="result-section investment-opportunities">
                <h2>Investment Opportunities</h2>
                <div className={isPaid ? '' : 'blurred-content'}>
                  <ul>
                    {investment_opportunities.map((opportunity, index) => (
                      <li key={index}>
                        <strong>Asset:</strong> {opportunity.asset} - <strong>Suggested Amount:</strong> ${opportunity.suggested_amount.toFixed(2)} - <strong>Potential Return:</strong> {opportunity.potential_return}% - <strong>Risk Level:</strong> {opportunity.risk_level}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}

            {hidden_gems && hidden_gems.length > 0 && (
              <div className="result-section hidden-gems">
                <h2>Hidden Gems</h2>
                <div className={isPaid ? '' : 'blurred-content'}>
                  <ul>
                    {hidden_gems.map((gem, index) => (
                      <li key={index}>
                        <strong>{gem.description}</strong>: {gem.insight} - <em>{gem.actionable_tip}</em>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
      {!isPaid && (
        <div className="payment-prompt">
          <p>To view the full analysis, please complete the payment.</p>
          <button onClick={() => setShowPaymentModal(true)}>Unlock Full Results</button>
        </div>
      )}
      <Elements stripe={stripePromise}>
        <Modal
          isOpen={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          showPayment={true}
          onPaymentSuccess={handlePaymentSuccess}
        />
      </Elements>
    </div>
  );
}

export default Results;